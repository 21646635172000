html,
body,
#root,
#map {
    width: 100vw;
    height: 100%;
}
body {
    padding: 0;
    margin: 0;
}

#map {
    background-color: #ffffff;
}
